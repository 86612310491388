import './WorldTime.css';
import { useEffect } from 'react';
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment-timezone';
import L from 'leaflet';

const cities = [
    { name: "New York", lat: 40.7128, lng: -74.0060, timezone: "America/New_York" },
    { name: "London", lat: 51.5074, lng: -0.1278, timezone: "Europe/London" },
    { name: "Tokyo", lat: 35.6895, lng: 139.6917, timezone: "Asia/Tokyo" },
    { name: "Sydney", lat: -33.8688, lng: 151.2093, timezone: "Australia/Sydney" },
    { name: "Paris", lat: 48.8566, lng: 2.3522, timezone: "Europe/Paris" },
    { name: "Dubai", lat: 25.276987, lng: 55.296249, timezone: "Asia/Dubai" },
    { name: "Moscow", lat: 55.7558, lng: 37.6173, timezone: "Europe/Moscow" },
    { name: "Beijing", lat: 39.9042, lng: 116.4074, timezone: "Asia/Shanghai" },
    { name: "Mumbai", lat: 19.0760, lng: 72.8777, timezone: "Asia/Kolkata" },
    { name: "São Paulo", lat: -23.5505, lng: -46.6333, timezone: "America/Sao_Paulo" },
    { name: "Mexico City", lat: 19.4326, lng: -99.1332, timezone: "America/Mexico_City" },
    { name: "Los Angeles", lat: 34.0522, lng: -118.2437, timezone: "America/Los_Angeles" },
    { name: "Cairo", lat: 30.0444, lng: 31.2357, timezone: "Africa/Cairo" },
    { name: "Istanbul", lat: 41.0082, lng: 28.9784, timezone: "Europe/Istanbul" },
    { name: "Buenos Aires", lat: -34.6037, lng: -58.3816, timezone: "America/Argentina/Buenos_Aires" },  
    { name: "Cairo", lat: 30.0444, lng: 31.2357, timezone: "Africa/Cairo" },
    { name: "Lagos", lat: 6.5244, lng: 3.3792, timezone: "Africa/Lagos" },
    { name: "Nairobi", lat: -1.2864, lng: 36.8172, timezone: "Africa/Nairobi" },
    { name: "Johannesburg", lat: -26.2041, lng: 28.0473, timezone: "Africa/Johannesburg" },
    { name: "Addis Ababa", lat: 9.0300, lng: 38.7400, timezone: "Africa/Addis_Ababa" },
    { name: "Kinshasa", lat: -4.4419, lng: 15.2663, timezone: "Africa/Kinshasa" },
    { name: "Casablanca", lat: 33.5731, lng: -7.5898, timezone: "Africa/Casablanca" },
    { name: "Algiers", lat: 36.7372, lng: 3.0866, timezone: "Africa/Algiers" },
    { name: "Khartoum", lat: 15.5007, lng: 32.5599, timezone: "Africa/Khartoum" },
    { name: "Accra", lat: 5.6037, lng: -0.1870, timezone: "Africa/Accra" },
    { name: "Dakar", lat: 14.6928, lng: -17.4467, timezone: "Africa/Dakar" },
    { name: "Abidjan", lat: 5.3485, lng: -4.0276, timezone: "Africa/Abidjan" },
    { name: "Kampala", lat: 0.3476, lng: 32.5825, timezone: "Africa/Kampala" },
    { name: "Harare", lat: -17.8292, lng: 31.0522, timezone: "Africa/Harare" },
    { name: "Luanda", lat: -8.8390, lng: 13.2894, timezone: "Africa/Luanda" },
    { name: "Beijing", lat: 39.9042, lng: 116.4074, timezone: "Asia/Shanghai" },
    { name: "Shanghai", lat: 31.2304, lng: 121.4737, timezone: "Asia/Shanghai" },
    { name: "Guangzhou", lat: 23.1291, lng: 113.2644, timezone: "Asia/Shanghai" },
    { name: "Shenzhen", lat: 22.5431, lng: 114.0579, timezone: "Asia/Shanghai" },
    { name: "Chengdu", lat: 30.5728, lng: 104.0668, timezone: "Asia/Shanghai" },
    { name: "Chongqing", lat: 29.5630, lng: 106.5516, timezone: "Asia/Shanghai" },
    { name: "Wuhan", lat: 30.5928, lng: 114.3055, timezone: "Asia/Shanghai" },
    { name: "Hangzhou", lat: 30.2741, lng: 120.1551, timezone: "Asia/Shanghai" },
    { name: "Xi'an", lat: 34.3416, lng: 108.9398, timezone: "Asia/Shanghai" },
    { name: "Nanjing", lat: 32.0603, lng: 118.7969, timezone: "Asia/Shanghai" },
    { name: "Tianjin", lat: 39.3434, lng: 117.3616, timezone: "Asia/Shanghai" },
    { name: "Changsha", lat: 28.2282, lng: 112.9388, timezone: "Asia/Shanghai" },
    { name: "Shenyang", lat: 41.8057, lng: 123.4315, timezone: "Asia/Shanghai" },
    { name: "Qingdao", lat: 36.0671, lng: 120.3826, timezone: "Asia/Shanghai" },
    { name: "Kunming", lat: 25.0389, lng: 102.7183, timezone: "Asia/Shanghai" },
    { name: "Melbourne", lat: -37.8136, lng: 144.9631, timezone: "Australia/Melbourne" },
    { name: "Brisbane", lat: -27.4698, lng: 153.0251, timezone: "Australia/Brisbane" },
    { name: "Perth", lat: -31.9505, lng: 115.8605, timezone: "Australia/Perth" },
    { name: "Adelaide", lat: -34.9285, lng: 138.6007, timezone: "Australia/Adelaide" },
  ];

const customIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [20, 30], // Adjust the width and height here (default is [25, 41])
    iconAnchor: [15, 41], // Adjust anchor if necessary
    popupAnchor: [0, -41], // Adjust popup anchor
  });
function WorldTime() {

    const [selectedCity, setSelectedCity] = useState(null);

    const handleCityClick = (city) => {
        setSelectedCity({
            ...city,
            time: moment.tz(city.timezone).format("YYYY-MM-DD HH:mm:ss"),
        });
    };


    useEffect(() => {

        const timeZones = [
            { name: "New York", timezone: "America/New_York" },
            { name: "London", timezone: "Europe/London" },
            { name: "Paris", timezone: "Europe/Paris" },
            { name: "Dubai", timezone: "Asia/Dubai" },
            { name: "Tokyo", timezone: "Asia/Tokyo" },
            { name: "Sydney", timezone: "Australia/Sydney" },
        ];

        function updateTimes() {

            const container = document.getElementById("timeZones");
            container.innerHTML = ""; // Clear previous times

            timeZones.forEach(zone => {
                const time = new Date().toLocaleString("en-US", { timeZone: zone.timezone, hour: '2-digit', minute: '2-digit', second: '2-digit' });
                const zoneDiv = document.createElement("div");
                zoneDiv.className = "timezone";
                zoneDiv.innerHTML = `<strong>${zone.name}</strong><br>${time}`;
                container.appendChild(zoneDiv);
            });
        }

        document.title = "World Time"

        // Initial call to display times immediately
        updateTimes();

        // Update the time every second
        const interval = setInterval(updateTimes, 1000);

        return () => clearInterval(interval);


    }, [])


    return (
        <>
            <div>
                <h1>World Time</h1>

                <div id="timeZones"></div>
            </div>
            <br/>
            <br/>

            <div>
                <h2>Click on a icon to show a city's time</h2>
                <MapContainer center={[20, 0]} zoom={2} style={{ height: "500px", width: "100%" }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {cities.map((city, index) => (
                        <Marker
                            key={index}
                            icon={customIcon} 
                            position={[city.lat, city.lng]}
                            size={[20,20]}
                            eventHandlers={{
                                click: () => handleCityClick(city),
                                mouseover: (e) => {
                                    e.target.openPopup();
                                  },
                                  mouseout: (e) => {
                                    e.target.closePopup();
                                  },
                            }}
                        >
                            <Popup closeButton={false}>
                                <strong>{city.name}</strong>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>

                {selectedCity && (
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <h3>Local Time in {selectedCity.name}</h3>
                        <p>{selectedCity.time}</p>
                    </div>
                )}
            </div>



        </>
    );
}

export default WorldTime;
